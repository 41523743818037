/* @flow */

import type { Category } from "shop-state/types";

import React from "react";
import { Link } from "react-router-dom";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import { HomeData } from "data";

import styles from "./styles.scss";

const AdventCalendarHero = ({
  category,
}: { category: Category | null }): React$Node => {
  const t = useTranslate();
  const home = useData(HomeData);
  const bannerImage =
    home.state === "LOADED" &&
    home.data.adventCalendar?.category.clsCatfieldsGeneralImgL !== undefined &&
    home.data.adventCalendar.category.clsCatfieldsGeneralImgL !== null ?
      home.data.adventCalendar.category.clsCatfieldsGeneralImgL : null;

  return (
    <>
      <div
        className={styles.hero}
        style={bannerImage ? { background: `url(${bannerImage.x2}) 95% 0 / cover no-repeat` } : {}}
      />
      <div className={styles.heroContent}>
        {category !== null ?
          <>
            <h1>{ category.name }</h1>
            <p>{ category.description }</p>
            <Link
              to={`/${t("ADVENT_CALENDAR.CATEGORY_URL")}`}
            >
              <Button variant="primary">
                {t("ADVENT_CALENDAR.BACK")}
              </Button>
            </Link>
          </> :
          <>
            <h1>{t("ADVENT_CALENDAR.HERO.HEADING")}</h1>
            <p>{t("ADVENT_CALENDAR.HERO.TEXT")}</p>
          </>
        }
      </div>
    </>
  );
};

export default AdventCalendarHero;
